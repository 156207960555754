
.article.single .article-wrap {
  padding: 0;
  margin-top: -9%;
  background: #fff;
  width: 100%;
  max-width: 900px;
}
@media ( min-width: 834px ) {
  .article.single .article-wrap {
    margin-top: -5%;
    padding: 0 50px;
  }
}
.heroimg {
  width: 100%;
  aspect-ratio: 5 / 3;
  position: relative;
  overflow: hidden;
  display: block;
  z-index: -1;
  margin-top: -1rem;
}
.heroimg img {
  position: absolute;
  z-index: 1;

  width: 100%;
  min-height: 100%;
  object-fit: cover;

  left: 50%;
  top: 50%;
  z-index: 0;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}
@media ( min-width: 834px ) {
  .heroimg {
    aspect-ratio: 5 / 2;
    margin-top: 0;
  }
}

/* article header
____________________________________________________ */
.article-header {
  width: 100%;
  padding: 0;
  margin-bottom: 1rem;
}
.article-header .caption {
  display: block;
  font-size: min(4.5vw, 24px);
  text-align: justify;
  font-weight: 700;
  color: rgba(var(--color00),1);
  letter-spacing: 0.05em;
  line-height: 1.4;
  margin-bottom: 0.5em;
}
.article-header .pubmeta {
  font-size: min(3.5vw, 13px);
  display: flex;
  align-items: center;
  margin-top: 0.5em;
}
.article-header .pubmeta .meta-time {
  display: inline-block;
  color: rgba(var(--color00),1);
  line-height: 1.4;
  margin: 0.25rem 0.5rem 0.25rem 0;
}
.article-header .pubmeta .meta-cat {
  color: rgba(var(--color05),1);
  margin: 0.25rem 0.5rem 0.25rem 0;
}

.article.single .article-header {
  padding: 5% 0 0 0;
}
.article.single .article-header .caption {
  text-align: center;
  font-size: min(5vw, 28px);
  margin-bottom: 5%;
}
@media ( min-width: 1281px ) {
  .article-header {
    margin-bottom: 1.5rem;
  }
  .article.single .article-header {
    padding: 7% 2rem 0 2rem;
  }
}

/* article body
____________________________________________________ */
.article-body {
  padding: 0;
  overflow: hidden;
  position: relative;
}
.article-body::after {
  content: '';
  display: table;
  clear: both;
}
.article-body p {
  line-height: 1.5;
  font-size: min(4vw, 16px);
  margin-bottom: 2rem;
  text-align: justify;
  /* text-indent: 1em; */
  position: relative;
}
.article-body p strong {
  font-weight: bold;
}
.article.single .article-body p {
  line-height: 1.9;
  font-size: min(4vw, 18px);
}
.subheadline {
  font-size: min(4vw, 16px);
  font-weight: 700;
  margin-top: 1em;
  margin-bottom: 1em;
}
.container_kei {
  border: solid 1px #dadada;
  line-height: 1.5;
  margin-bottom: 1em;
  margin-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
}
.kei_kakomi {
  border: solid 1px #dadada;
  margin: 1em 0;
  padding: 2px 16px;
  overflow: auto;
  width: auto;
}

/* ペイウォール */
.article-body > .limit-content::before {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(255,255,255,1), rgba(255,255,255,0));
}
.login .article-body > .limit-content::before {
  display: none;
}



.article-headline {
  font-size: min(5vw, 24px);
  border-left: 0;
  margin: 2rem 0 2rem 0;
  padding: 0.3em 0 0.15em 0.5em;
  font-weight: 700;
}

.article-headline.hazBullet {
  font-size: min(5vw, 20px);
  border-left: 0.5em solid rgba(var(--color00),0.5);
  margin: 2rem 0 1rem 0;
}


*:not(.toc) + .article-headline,
.article.single *:not(.toc) + .article-headline {
  margin-top: 4rem;
}
@media ( min-width: 1281px ) {
  .article.single .article-body>:not(.has-fullimg,.youtube_aspect) {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}






/* offer with article
____________________________________________________ */
.offer-panel {
  width: 100%;
  padding: 7%;
}
.login .offer-panel {
  /* display: none; */
}
.offer-panel .inner {
  display: block;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 5% 4%;
  text-align: center;
}
.offer-panel .offer-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.offer-panel .offer-info p {
  display: flex;
  align-items: center;
  padding: 0.25rem .25rem;
  font-size: min(4vw, 16px);
  line-height: 1.6;
  font-weight: 700;
}
.offer-panel .offer-info .offer-info-text {
  font-size: min(4vw, 18px);
  font-weight: 700;
}
.offer-panel .offer-info .offer-info-text em {
  display: flex;
  align-items: center;
  color: crimson;
  font-weight: 700;
}
.offer-panel .offer-info .offer-info-remain {
  font-size: min(4vw, 14px);
}
.offer-panel .offer-info .offer-info-remain span {
  font-size: min(4vw, 18px);
}
.offer-panel .offer-info .appeal {
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.offer-panel .offer-info .appeal .viewsLeft {
  padding: 0 0.5rem 0.5rem 0;
}
.offer-panel .offer-info .appeal .viewsLeft em {
  font-size: 225%;
  font-weight: 700;
  display: inline-block;
  line-height: 1;
  padding: 0 0.1em 0 0.25em;
}
@media( min-width: 641px ) {
  .offer-panel .offer-info .appeal {
    flex-direction: row;
    align-items: baseline;
  }
}


.offer-panel .tofaq {
  display: inline-flex;
  align-items: center;
  color: #000;
}
.offer-panel .tofaq::before {
  margin-right: 0.5em;
}

.offer-panel .offer-info .remain-meter {
  display: flex;
  justify-content: space-between;
  width: 70%;
  background: rgba(var(--color08), 1);
  border: 2px solid rgba(var(--color01), 0.5);
  margin: 1rem auto;
  position: relative;
}
.offer-panel .offer-info .remain-meter::before {
  content: '';
  display: block;
  background: rgba(var(--color01), 1);
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: auto;
  z-index: 0;
}
.offer-panel .offer-info .remain-meter[data-remain="5"]::before { width: 100%; }
.offer-panel .offer-info .remain-meter[data-remain="4"]::before { width: 80%; }
.offer-panel .offer-info .remain-meter[data-remain="3"]::before { width: 60%; }
.offer-panel .offer-info .remain-meter[data-remain="2"]::before { width: 40%; }
.offer-panel .offer-info .remain-meter[data-remain="1"]::before { width: 20%; }
.offer-panel .offer-info .remain-meter[data-remain="0"]::before { width: 0; }
.offer-panel .offer-info .remain-meter span {
  width: calc( 100% / 5 );
  z-index: 1;
  display: block;
  height: 1.5rem;
  border-right: 2px solid #b5d1ff;
}
.offer-panel .offer-info .remain-meter span:last-child {
  border-right: none;
}


/* gallery with article
____________________________________________________ */
.article-media-wrap {
  width: 100%;
  float: none;
  position: relative;
  z-index: 100;
}
.gallery-within-article {
  width: 100%;
  background: rgba(var(--color00),0.05);
  border: 1px solid rgba(var(--color00),0.3);
  padding: 0.5rem;
  border-radius: 10px;
  position: relative;
  margin-bottom: 1rem;
}
.gallery-within-article + .gallery-within-article {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.gallery-within-article .current-photo .thumb {
  aspect-ratio: 4 / 3;
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
}
.gallery-within-article .current-photo a.thumb::before {
  content: '';
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 0;
  font-size: 9px;
  width: min(12vw, 42px);
  height: min(12vw, 42px);
  display: flex;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><defs><style>.cls-1,.cls-2{fill:%233471d5;}.cls-3{fill:none;stroke-linecap:round;stroke-width:2.07px;}.cls-3,.cls-2{stroke:%23fff;stroke-miterlimit:10;}.cls-2{stroke-width:3px;}</style></defs><path class="cls-2" d="M24.88,23.31l-2.83-3.37c.71-.93,1.16-2.03,1.27-3.23,.16-1.68-.34-3.33-1.41-4.63-1.07-1.31-2.59-2.11-4.27-2.28-3.49-.34-6.57,2.21-6.91,5.68-.16,1.68,.34,3.33,1.41,4.63,1.07,1.31,2.59,2.11,4.27,2.28,.21,.02,.42,.03,.62,.03,.74,0,1.47-.13,2.15-.38l2.97,3.54c.35,.42,.85,.63,1.36,.63,.4,0,.81-.14,1.14-.41,.75-.63,.85-1.75,.22-2.5Z"/><path class="cls-1" d="M24.88,23.31l-2.83-3.37c.71-.93,1.16-2.03,1.27-3.23,.16-1.68-.34-3.33-1.41-4.63-1.07-1.31-2.59-2.11-4.27-2.28-3.49-.34-6.57,2.21-6.91,5.68-.16,1.68,.34,3.33,1.41,4.63,1.07,1.31,2.59,2.11,4.27,2.28,.21,.02,.42,.03,.62,.03,.74,0,1.47-.13,2.15-.38l2.97,3.54c.35,.42,.85,.63,1.36,.63,.4,0,.81-.14,1.14-.41,.75-.63,.85-1.75,.22-2.5Z"/><line class="cls-3" x1="17.03" y1="13.77" x2="17.03" y2="18.44"/><line class="cls-3" x1="19.37" y1="16.1" x2="14.69" y2="16.1"/></svg>');
  transition: all .25s ease;
}
.gallery-within-article .current-photo .thumb img {
  position: absolute;
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  left: 50%;
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}
@keyframes opa {
  0% {opacity: 0; filter: blur(7px);}
  99%,to {opacity: 1; filter: blur(0);}
}
.gallery-within-article .current-photo figcaption {
  display: block;
  font-size: min(4.5vw, 12px);
  color: rgba(var(--color00),0.85);
  letter-spacing: 0;
  line-height: 1.4;
  margin: 1em 0;
  min-height: calc( 3em * 1.4 );
  word-break: break-word;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.gallery-within-article > .gallery li {
  width: calc( 100% / 5 - 2% );
  margin-right: 2%;
  margin-bottom: 2%;
  float: left;
}
@media ( min-width: 641px ) {
  .article-media-wrap {
    width: 40%;
    float: left;
    overflow: hidden;
    margin: 5px 2rem 1rem 0;
  }
}
@media ( max-width: 1280px ) {
  .gallery-within-article .current-photo figcaption  {
    width: calc(100vw / 3 );
  }
}
@media ( max-width: 640px ) {
  .gallery-within-article .current-photo figcaption  {
    width: calc(100vw / 1.15 );
  }
}

/* article toc
____________________________________________________ */
.toc {
  background: rgba(var(--color00),0.05);
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 3rem;
}
.toc .toc-index {
  font-size: min(4vw, 18px);
  font-weight: 700;
  color: rgba(var(--color00),0.7);
  display: flex;
  cursor: pointer;
}
.toc .toc-index:hover {
  color: rgba(var(--color00),1);
}
.toc .toc-index::before {
  content: '\0025b6';
  margin-right: 0.25em;
}
.toc.on .toc-index::before {
  content: '\0025bc';
}
.toc .toc-index::after {
  content: '\00ff08\0030af\0030ea\0030c3\0030af\003067\003054\0089a7\00306b\00306a\00308c\00307e\003059\00ff09';
}
.toc.on .toc-index::after {
  content: '';
}
.toc ul {
  height: 0;
  opacity: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;
  font-size: min(3.75vmin, 18px);
  transition: all .75s ease;
}
.toc.on ul {
  padding: 1.5rem 0 0 0;
  height: auto;
  opacity: 1;
  transition: all .75s ease;
}
.toc ul li {
  padding: 1em 0 1em 1em;
  border-bottom: 1px solid #ccc;
}
.toc ul li:last-of-type {
  border: none;
  padding-bottom: 0;
}
.toc ul li a {
  font-size: min(5vw, 18px);
  color: rgba(var(--color00),0.7);
  line-height: 1;
  display: block;
}
.toc ul li a:hover {
  color: rgba(var(--color00),1);
}
@media ( min-width: 1281px ) {
  .toc {
    padding: 2rem;
  }
}


.article-footer {
  padding: 0 2rem;
  margin-bottom: 3rem;
}
.article-footer li {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  padding: 0.5rem 0;
}
.article-footer li.meta-socials {
  padding: 2rem 0 1rem 0;
}
.article-footer li a {
  color: rgba(var(--color00),1);
  font-weight: 700;
}

.article-pager {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 2rem;
  border-top: 1px solid rgba(var(--color00),0.25);
  border-bottom: 1px solid rgba(var(--color00),0.25);
}
.article-pager a {
  width: 100%;
  display: flex;
  align-items: center;
  color: rgba(var(--color00),0.75);
  padding: 1em 0;
  line-height: 1.7;
}
.article-pager a:hover {
  color: rgba(var(--color00),1);
}
.article-pager a.prev {
  border-bottom: 1px solid rgba(var(--color00),0.25);
  padding-right: 2em;
}
.article-pager a.next {
  padding-left: 2em;
}
.article-pager a.next:only-child {
  margin: 0 0 0 auto;
  border-left: 1px solid rgba(var(--color00),0.25);
}
.article-pager a.prev::before {
  content: '\0025c0';
  font-size: 1.5em;
}
.article-pager a.next::after {
  content: '\0025b6';
  font-size: 1.5em;
}
@media ( min-width: 641px ) {
  .article-pager {
    flex-direction: row;
    padding: .75rem;
  }
  .article-pager a {
    width: calc( 100% / 2 );
  }
  .article-pager a.prev {
    border-bottom: none;
    border-right: 1px solid rgba(var(--color00),0.25);
  }
}


/* Adjust aspect ratio of youtube iframe
____________________________________________________ */
iframe.youtube_aspect {
  aspect-ratio: 4/3;
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.article-body>iframe.youtube_aspect {
  margin: 0 auto 2rem;
}

@media (700px <= width) {
  .article-body>iframe.youtube_aspect {
      max-width: 639px;
  }
}
